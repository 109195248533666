export const appFilesArray = [
    '_dummy',
    'Activities',
    'AppointmentStatus',
    'AppointmentTypes',
    'Appointments',
    'Attendances',
    'Businesses',
    'ClockIns',
    'Currencies',
    'CustomerTypes',
    'Customers',
    'Departments',
    'Dues',
    'Employees',
    'ExpenseTypes',
    'Expenses',
    'GroupSchedules',
    'Groups',
    'InflowItems',
    'Inflows',
    'Instructors',
    'Inventories',
    'Measurements',
    'Memberships',
    'OutflowItems',
    'Outflows',
    'PaymentMethods',
    'PaymentTypes',
    'Payments',
    'Plans',
    'Products',
    'SaleItems',
    'Sales',
    'SalesPersons',
    'TaskStatus',
    'TaskTypes',
    'Tasks',
    'TaxRates',
    'Users',
    'Validities',
]