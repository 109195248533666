export const menuLeft = [
  {
    group: "CRM",
    icon: "fas fa-users",
    routes: [
      "customers.browse",
      "appointments.browse",
      "tasks.browse",
      "measures.browse",
      "customer_types.browse",
      "appointment_types.browse",
      "appointment_status.browse",
      "task_types.browse",
      "task_status.browse",
    ]
  },
  {
    group: "Memberships",
    icon: "fas fa-id-card",
    routes: [
      "memberships.browse",
      "plans.browse",
    ]
  },
  {
    group: "AccessControl",
    icon: "fas fa-door-closed",
    routes: [
      "attendances.browse",
    ]
  },
  {
    group: "Activities",
    icon: "fas fa-chalkboard-teacher",
    routes: [
      "activities.browse",
      "groups.browse",
      "group_members.browse",
      "group_schedules.browse",
    ]
  },
  {
    group: "Finances",
    icon: "fas fa-coins",
    routes: [
      "dues.browse",
      "payments.browse",
      "payment_methods.browse",
      "expenses.browse",
      "expense_types.browse",
    ]
  },
  {
    group: "POS",
    icon: "fas fa-cash-register",
    routes: [
      "sales.browse",
      "sale_items.browse",
      "products.browse",
      "product_types.browse",
      "inventories.browse",
      "departments.browse",
      "inflows.browse",
      "inflow_items.browse",
      "outflows.browse",
      "outflow_items.browse",
    ]
  },
  {
    group: "Personnel",
    icon: "fas fa-user-clock",
    routes: [
      "clock_ins.browse",
      "employees.browse",
      "sales_persons.browse",
      "instructors.browse",
    ]
  },
  {
    group: "Users",
    icon: "fas fa-users-cog",
    routes: [
      "users.browse",
    ]
  },
  {
    group: "Settings",
    icon: "fas fa-cog",
    routes: [
      "businesses.browse",
      "payment_types.browse",
      "validities.browse",
      "tax_rates.browse",
    ]
  },
  {
    group: "Charts",
    icon: "fas fa-chart-pie",
    routes: [
      "dummy.chart"
    ]
  },
  {
    group: "Reports",
    icon: "fas fa-list",
    routes: [
      "customers.report_alpha",
      "service_dues.report_chrono",
      "dummy.report"
    ]
  }

]
