import axios from 'axios'
import router from "../router";

export default {
  namespaced: true,

  state: {
    authenticated: false,
    routes: [],
    user: null,
    verified: false
  },

  getters: {
    allowedRoute: (state) => (route) => {
      return state.routes.includes(route)
    },

    authenticated(state) {
      return state.authenticated
    },

    user(state) {
      return state.user
    },

    verified(state) {
      return state.verified
    }
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },

    SET_ROUTES(state) {
      if (state.user) {
        router.getRoutes().forEach((route) => {
          if (route.meta.roles) {
            let roles = route.meta.roles.split(',').map(string => string.trim())
            if (roles.includes(state.user.role)) state.routes.push(route.name)
          }
        });
      } else {
        state.routes = []
      }
    },

    SET_USER(state, value) {
      state.user = value

      if (state.user) {
        router.getRoutes().forEach((route) => {
          if (route.meta.roles) {
            let roles = route.meta.roles.split(',').map(string => string.trim())
            if (roles.includes(state.user.role)) state.routes.push(route.name)
          }
        });
      } else {
        state.routes = []
      }

      state.verified = (state.user != null && state.user.email_verified_at != null)
      state.authenticated = state.user != null
    },

    SET_VERIFIED(state) {
      state.verified = (state.user != null && state.user.email_verified_at != null)
    }
  },

  actions: {
    async forgotPassword(context, form) {
      await axios.post('/api/forgot-password', form)
    },

    async login({ dispatch }, form) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('api/login', form)
      return dispatch('me')
    },

    async logout({ dispatch }) {
      await axios.post('/api/logout')

      return dispatch('me')
    },

    me({ commit }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_USER', response.data)
      }).catch((e) => {
        commit('SET_USER', null)
        showError(e)
      })
    },

    async notify() {
      await axios.post('/api/email/verification-notification')
    },

    async register({ dispatch }, form) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/api/register', form)
      return dispatch('me')
    },

    async resetPassword(context, form) {
      await axios.post('/api/reset-password', form)
    },

    async verify({ dispatch }, payload) {
      const params = { expires: payload.expires, signature: payload.signature }

      await axios.get(`/api/email/verify/${payload.id}/${payload.hash}`, { params }) // params = query string.

      return dispatch('me')
    },

  }
}

function showError(e) {
  if (e.response && e.response.status == 401) return // Unauthenticated.

  if (e.response && e.response.data && e.response.data.message !== undefined) {
    alert('Authentication error!"\r\nResponse: ' + e.response.data.message)
  } else if (e.message) {
    alert('Authentication error!\r\nMessage: ' + e.message)
  } else if (process.env.VUE_APP_DEBUG) {
    alert('Authentication error!\r\n' + e.toString())
  } else alert('Authentication error!\r\n' + e.toString().substring(0, 50) + '...')
}

