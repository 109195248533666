<template>
  <v-app id="app">
    <v-app-bar app clipped-left clipped-right :color="color" dark dense>
      <v-app-bar-nav-icon
        v-if="authVerified"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>MemberFit</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu>
          <template #activator="{ on: onMenu }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-chip
                  v-bind="attrs"
                  v-on="{ ...onMenu, ...onTooltip }"
                  class="ma-2"
                  :color="color"
                  text-color="white"
                >
                  <v-icon left>fas fa-globe-americas</v-icon>
                  {{ lang }}
                </v-chip>
              </template>

              <span>{{ $t("language") }}</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, i) in langItems"
              :key="i"
              @click="changeLang(item.value)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip v-if="authAuthenticated" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              :color="color"
              text-color="white"
              :to="{ name: 'account' }"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>fas fa-user</v-icon>
              {{ initials }}
            </v-chip>
          </template>
          <span>{{ $t("myAccount") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              :color="color"
              text-color="white"
              :to="logRoute"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>fas fa-power-off</v-icon>
            </v-chip>
          </template>
          <span>{{ logLabel }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      v-if="authVerified"
      v-model="drawer"
      app
      clipped
      expand-on-hover
      floating
      left
    >
      <v-list dense nav class="py-0">
        <v-divider></v-divider>
        <v-list-group v-for="group in menuLeft" :key="group.title" no-action>
          <template v-slot:prependIcon>
            <v-icon :color="color">{{ group.icon }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="group.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="route in group.routes"
            :key="route.title"
            replace
            :to="route.route"
          >
            <v-list-item-content>
              <v-list-item-title v-text="route.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- Vue components inserted here by vue router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer :color="color" app>
      <v-chip
        href="https://www.vsoftla.com/memberfit"
        :color="color"
        link
        target="_blank"
        text-color="white"
        >Powered by www.vsoftla.com</v-chip
      >
      <v-spacer></v-spacer>
      <span class="white--text">{{ $settings.version }}</span>
      <v-spacer></v-spacer>
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

    <v-snackbar
      v-model="snackbar.active"
      app
      centered
      :color="snackbar.color"
      content-class="text-center"
      multi-line
      :timeout="snackbar.timeout"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="snackbar.active = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { menuLeft } from "./menuLeft";

// Based on https://github.com/stephanfo/VueI18nLazyExample/tree/master/src/lang
import {
  loadLanguageAsync,
  languagesAvailable,
  navigatorCloseLanguage,
} from "@/plugins/i18n";

export default {
  name: "Dashboard",

  data() {
    return {
      color: this.$settings.appColor,
      lang: "",
      langItems: languagesAvailable,
      drawer: true,
    };
  },

  computed: {
    ...mapGetters({
      authAlloweRoute: "auth/allowedRoute",
      authAuthenticated: "auth/authenticated",
      authUser: "auth/user",
      authVerified: "auth/verified",
      snackbar: "app/snackbar",
    }),

    logLabel() {
      return this.authAuthenticated
        ? this.$helper.tRoute("logout")
        : this.$helper.tRoute("login");
    },

    logRoute() {
      return this.authAuthenticated ? { name: "logout" } : { name: "login" };
    },

    menuLeft() {
      return this.userMenu(menuLeft);
    },

    initials() {
      return this.authUser ? this.authUser.initials : "";
    },
  },

  created() {
    this.lang = navigatorCloseLanguage();
  },

  methods: {
    changeLang: function (lang) {
      if (lang != this.lang) {
        loadLanguageAsync(lang);
        this.lang = lang;
      }
    },

    userMenu(sideMenu) {
      if (!this.authUser) return [];

      let menu = [];
      let vm = this;

      sideMenu.forEach((group) => {
        let routes = group.routes.filter(function (route) {
          return vm.authAlloweRoute(route);
        });

        if (routes.length) {
          let menuRoutes = [];

          routes.forEach((route) => {
            menuRoutes.push({
              title: this.$helper.tRoute(route),
              route: { name: route },
            });
          });

          menu.push({
            title: this.$t("Menu." + group.group),
            icon: group.icon,
            routes: menuRoutes,
          });
        }
      });

      return menu;
    },
  },
};
</script>
