// Global helper functions. For details about using plugins see: 
// https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components

import { i18n } from '@/plugins/i18n';

export default {

  error(e) {
    /* Error properties and samples
      e.ToString: e.g. Error: Request failed with status code 403
      e.message: e.g. Request failed with status code 403
      e.response.data.message: e.g. Invalid signature. e.g. The given data is invalid. (api exception message)
      e.response.data.errors: (api field validation errors) 
      e.response.status: e.g. 403 
      e.response.statusText: e.g. Forbidden
      e.name: e.g. Error
      Other N/A properties: e.stack, e.config, e.response.data.exception, e.response.data.file 
        e.response.data.line, e.response.data.trace, e.response.headers, e.response.config, e.response.request
    */
    let error = '';

    if (e.response && e.response.data && e.response.data.message !== undefined) {
      error = e.response.data.message
    } else if (e.message) {
      error = e.message
    } else if (process.env.VUE_APP_DEBUG) {
      error = e.toString()
    } else {
      error = e.toString().substring(0, 50) + "...";
    }

    if (error == "The given data was invalid.") return "invalidData"

    if (error.search(" violation: 1451 ") != -1) return "destroyError"

    if (error.search(" violation: 1062 ") != -1) return "dupEntryError"

    return error
  },

  errorStatus(e) {
    if (e.response && e.response.status !== undefined) return e.response.status
  },

  formatItem(item, formats) {
    for (var column in formats) {
      if (column in item) {
        if (formats[column] == "Decimal") {
          item[column] = this.formatDecimal(item[column])
        } else if (formats[column].startsWith("Integer")) {
          item[column] = this.formatInteger(item[column])
        } else if (formats[column] == "Money") {
          item[column] = this.formatMoney(item[column])
        } else if (formats[column] == "Rate") {
          item[column] = this.formatRate(item[column])
        } else if (formats[column] == "Select*") {
          item[column] = this.formatSelect(item[column], column)
        }
      }
    }
  },

  formatItems(items, formats) {
    items.forEach((item) => {
      this.formatItem(item, formats)
    });
  },

  formatDecimal(value) {
    return new Intl.NumberFormat("en-US", { style: "decimal" }).format(value)
  },

  formatInteger(value) {
    return new Intl.NumberFormat("en-US", { style: "decimal" }).format(value)
  },

  formatMoney(value) {
    return new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(value)
  },

  formatRate(value) {
    return new Intl.NumberFormat("en-US", { style: "decimal", maximumSignificantDigits: 4 }).format(value)
  },

  formatSelect(value, name) {
    let array = i18n.t(`Selects.${name}`).split(',')

    for (let index = 0; index < array.length; index = index + 2) {
      if (array[index] == value) return array[index + 1]
    }
  },

  lcaseFirst(string) {
    if (string) return string.charAt(0).toLowerCase() + string.slice(1)
  },

  moneySymbol() {
    return "$"; // TBD: Retrieve from user/company profile.
  },

  selectItems(name) {
    let items = []

    let array = i18n.t(`Selects.${name}`).split(',')

    for (let index = 0; index < array.length; index = index + 2) {
      items.push({ text: array[index + 1], value: array[index] })
    }

    return items
  },

  setFieldErrors(e, vm) {
    if (e.response && e.response.data && e.response.data.errors !== undefined) {
      for (var f in e.response.data.errors) {
        vm.$refs[f].errorMessages = e.response.data.errors[f]
      }
    }
  },

  snakeCase(string) {
    return string.replace(/\.?([A-Z]+)/g, function (x, y) { return "_" + y.toLowerCase() }).replace(/^_/, "");
  },

  // Translate route title.
  tRoute(route) {
    if (route == undefined) return ''

    let action, table, tableSingular, tablePlural = ''
    const r = route.split(".")

    if (r.length > 1) {
      table = r[0]
      action = r[1]
      tableSingular = this.tTable(this.snakeCase(table), 1)
      tablePlural = this.tTable(this.snakeCase(table), 2)
    } else {
      action = r[0]
    }

    if (i18n.te(`Routes.${table}_${action}`, { tableSingular, tablePlural })) {
      return i18n.t(`Routes.${table}_${action}`, { tableSingular, tablePlural })
    }

    return i18n.t(`Routes._${action}`, { tableSingular, tablePlural })
  },

  // Translate table or object.
  tTable(name, count = 1) {
    return i18n.tc(`Tables.${name}`, count)
  },

  ucaseFirst(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  },
};