// Use material design icons for default icons of vuetify components
import '@mdi/font/css/materialdesignicons.css' 
// Use font awesome 5 icons for v-icons.
import '@fortawesome/fontawesome-free/css/all.css' 
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import {i18n} from '@/plugins/i18n';

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
})
