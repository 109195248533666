var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":"","color":_vm.color,"dark":"","dense":""}},[(_vm.authVerified)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-toolbar-title',[_vm._v("MemberFit")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.color,"text-color":"white"}},'v-chip',_vm.attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-globe-americas")]),_vm._v(" "+_vm._s(_vm.lang)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("language")))])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.langItems),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.changeLang(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),(_vm.authAuthenticated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.color,"text-color":"white","to":{ name: 'account' }}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-user")]),_vm._v(" "+_vm._s(_vm.initials)+" ")],1)]}}],null,false,3736088743)},[_c('span',[_vm._v(_vm._s(_vm.$t("myAccount")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.color,"text-color":"white","to":_vm.logRoute}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-power-off")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.logLabel))])])],1)],1),(_vm.authVerified)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","expand-on-hover":"","floating":"","left":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-divider'),_vm._l((_vm.menuLeft),function(group){return _c('v-list-group',{key:group.title,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(_vm._s(group.icon))])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(group.title)}})],1)]},proxy:true}],null,true)},_vm._l((group.routes),function(route){return _c('v-list-item',{key:route.title,attrs:{"replace":"","to":route.route}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(route.title)}})],1)],1)}),1)})],2)],1):_vm._e(),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"color":_vm.color,"app":""}},[_c('v-chip',{attrs:{"href":"https://www.vsoftla.com/memberfit","color":_vm.color,"link":"","target":"_blank","text-color":"white"}},[_vm._v("Powered by www.vsoftla.com")]),_c('v-spacer'),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$settings.version))]),_c('v-spacer'),_c('span',{staticClass:"white--text"},[_vm._v("© "+_vm._s(new Date().getFullYear()))])],1),_c('v-snackbar',{attrs:{"app":"","centered":"","color":_vm.snackbar.color,"content-class":"text-center","multi-line":"","timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',_vm._b({on:{"click":function($event){_vm.snackbar.active = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar.active),callback:function ($$v) {_vm.$set(_vm.snackbar, "active", $$v)},expression:"snackbar.active"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }