<template>
  <v-container fluid>
    <h1>{{ $t("routeNotFound") }}</h1>
  </v-container>
</template>

<script>
export default {
  name: "notFound",
};
</script>
