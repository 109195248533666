import $vuetify from '@/lang/en_vuetify';

export default {
  actions: 'Actions',
  accessDenied: 'Access Denied!',
  browseError: 'Error browsing {0}: ',
  cloneError: 'Error creating {0}: ',
  cloneSuccess: '{0} created.',
  close: 'Close',
  createError: 'Error creating {0}: ',
  createSuccess: '{0} created.',
  editError: 'Error updating {0}: ',
  editSuccess: '{0} updated.',
  delete: 'Delete',
  delete_access_groups_warning: 'Schedules and Members related to this Group will also be deleted.',
  delete_classes_warning: 'Schedules and Members related to this Class will also be deleted.',
  delete_customers_warning: 'Appointments, Tasks, Measurements and FingerPrints related to this Comtact will also be deleted.',
  delete_employees_warning: 'Attendances related to this Employee will also be deleted.',
  deleteError: 'Error deleting {0}: ',
  delete_groups_warning: 'Schedules and Members related to this Group will also be deleted.',
  delete_inscriptions_warning: 'Dues, Payments and Attendances related to this MembershipMembresía will also be deleted.',
  delete_service_dues_warning: 'Paymenst related to this Due will also be deleted.',
  deleteSuccess: '{0} deleted.',
  destroy_activity_categories_error: 'Cannot delete this Category because it has related Types.',
  destroy_activity_fees_error: 'Cannot delete this Type because it has related Memberships.',
  destroy_appointment_categories_error: 'Cannot delete this Category because it has related Appointments.',
  destroy_appointments_status_error: 'Cannot delete this Status because it has related Appointments.',
  destroy_classes_error: 'Cannot delete this Class because it has related Attendances.',
  destroy_concepts_error: 'Cannot delete this because it has related Dues and/or Payments.',
  destroy_customer_categories_error: 'Cannot delete this Category because it has related Contacts.',
  destroy_customers_error: 'Cannot delete this Contact porque because it has related Invoices and/or Memberships and/or Dues and/or Payments and/or Sales.',
  destroy_expense_categories_error: 'Cannot delete this Category because it has related Expenses.',
  destroy_lockers_error: 'Cannot delete this Locker because it has related Memberships .',
  destroy_sales_persons_error: 'Cannot delete this Salesperson because it has related Contacts .',
  destroy_service_dues_error: 'Cannot delete this Due because it has related Payments.',
  destroy_service_payments_error: 'Cannot delete this Payment because it has related Invoices.',
  destroy_task_categories_error: 'Cannot delete this Category because it has related Tasks.',
  destroy_task_status_error: 'Cannot delete this Status because it has related Tasks.',
  doesNotExist: '{0} does not exist.',
  dupEntryError: '{0} duplicated.',
  fieldRequired: 'Field required.',
  invalidData: 'Invalid data!',
  language: 'Language',
  myAccount: 'My account',
  routeNotFound: 'Page not found!',
  save: 'Save',
  search: 'Search',
  searchIn: 'Search in',
  update: 'Update',
  validNumber: 'Enter a valid number.',

  // Auth related:
  Auth: {
    alreadyRegistered: 'Already have an account?',
    emailVerified: 'Your email has been verified!',
    emailPasswordResetLink: 'Email Password Reset Link',
    forgotPassword: 'Forgot your password?',
    forgotPasswordError: 'Error sending password reset link: ',
    forgotPasswordText: 'No problem. Just let us know your email address and we will email you a password reset link that will allow your to choose a new one.',
    invalidSignature: 'Error verifying email: Invalid signature',
    login: 'Log in',
    loginError: 'Error logging in: ',
    logout: 'Log out',
    logoutError: 'Error logging out: ',
    minChars: 'Min {0} characters.',
    notifyError: 'Error resending notification: ',
    notifyText: 'Thanks for creating your account! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.',
    passwordHint: 'At least {0} characters.',
    passwordReset: 'Your password has been reset.',
    register: 'Create Account',
    registerError: 'Error creating account: ',
    resetLinkEmailed: 'We have emailed your password reset link.',
    resetPasswordError: 'Error resetting password: ',
    resendVerification: 'Resend verification email',
    resetPassword: 'Reset Password',
    verificationResent: 'A new verification link has been sent to the email address you provided during account creation.',
    verifyError: 'Error verifying email: ',
    validEmail: 'Field must be a valid email address.',
    wantToRegister: 'Do you want to create an account?',
  },

  // Column labels.
  Cols: {
    activities: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    appointment_status: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    appointment_types: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    appointments: {
      appointment_status: 'Appointment Status',
      appointment_status_id: 'Appointment Status',
      appointment_type: 'Appointment Type',
      appointment_type_id: 'Appointment Type',
      customer: 'Customer',
      customer_id: 'Customer',
      date_time: 'Date Time',
      description: 'Description',
      id: 'Id',
      note: 'Note',
      sales_person: 'Sales Person',
      sales_person_id: 'Sales Person',
    },

    attendances: {
      activity: 'Activity',
      activity_id: 'Activity',
      check_in_date_time: 'Check In Date Time',
      check_out_date_time: 'Check Out Date Time',
      customer: 'Customer',
      customer_id: 'Customer',
      id: 'Id',
      inscription: 'Inscription',
      inscription_id: 'Inscription',
      instructor: 'Instructor',
      instructor_id: 'Instructor',
      note: 'Note',
    },

    businesses: {
      currency: 'Currency',
      currency_id: 'Currency',
      id: 'Id',
      licence_expiration_date: 'Licence Expiration Date',
      licence_time_stamp: 'Licence Time Stamp',
      max_failed_attempts: 'Max Failed Attempts',
      min_pwd_length: 'Min Pwd Length',
      name: 'Name',
      note: 'Note',
      pwd_expiration_days: 'Pwd Expiration Days',
      service_dues_preadvice_days: 'Service Dues Preadvice Days',
      service_dues_tolerance_days: 'Service Dues Tolerance Days',
      ticket_footer: 'Ticket Footer',
      ticket_header: 'Ticket Header',
    },

    clock_ins: {
      check_in_date_time: 'Check In Date Time',
      check_out_date_time: 'Check Out Date Time',
      employee: 'Employee',
      employee_id: 'Employee',
      id: 'Id',
      note: 'Note',
    },

    currencies: {
      code: 'Code',
      decimals_separator: 'Decimals Separator',
      id: 'Id',
      name: 'Name',
      note: 'Note',
      thousands_separator: 'Thousands Separator',
    },

    customer_types: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    customers: {
      address: 'Address',
      allergies: 'Allergies',
      birthday: 'Birthday',
      blood_type: 'Blood Type',
      customer_since: 'Customer Since',
      customer_type: 'Customer Type',
      customer_type_id: 'Customer Type',
      emergency_contact: 'Emergency Contact',
      emergency_phone: 'Emergency Phone',
      emergency_relationship: 'Emergency Relationship',
      facebook: 'Facebook',
      id: 'Id',
      instagram: 'Instagram',
      mail: 'E Mail',
      marketing_channel: 'Marketing Channel',
      medical_alert: 'Medical Alert',
      name: 'Name',
      note: 'Note',
      other_phone: 'Other Phone',
      other_social_networks: 'Other Social Networks',
      other_sports: 'Other Sports',
      phone: 'Phone',
      photo_file: 'Photo File',
      practiced_sport: 'Practiced Sport',
      profession: 'Profession',
      sales_person: 'Sales Person',
      sales_person_id: 'Sales Person',
      sector: 'Sector',
      sex: 'Sex',
      smokes: 'Smokes',
      sport_practiced: 'Sport Practiced',
      surgery: 'Surgery',
      surgery_description: 'Surgery Description',
      tax_code: 'Tax Code',
      tax_domicile: 'Tax Domicile',
      tax_name: 'Tax Name',
      time_not_exercizing: 'Time Not Exercizing',
      twitter: 'Twitter',
    },

    departments: {
      id: 'Id',
      image_file: 'Image File',
      name: 'Name',
      note: 'Note',
    },

    dues: {
      amount: 'Amount',
      concept: 'Concept',
      date: 'Date',
      id: 'Id',
      membership: 'Membership',
      membership_id: 'Membership',
      note: 'Note',
    },

    employees: {
      address: 'Address',
      curp: 'C U R P',
      department: 'Department',
      dob: 'D O B',
      hire_date: 'Hire Date',
      id: 'Id',
      leave_date: 'Leave Date',
      mail: 'E Mail',
      name: 'Name',
      note: 'Note',
      other_phones: 'Other Phones',
      phone: 'Phone',
      photo_file: 'Photo File',
      position: 'Position',
      sex: 'Sex',
      shift: 'Shift',
      ssn: 'S S N',
      tax_code: 'Tax Code',
    },

    expense_types: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    expenses: {
      amount: 'Amount',
      beneficiary: 'Beneficiary',
      concept: 'Concept',
      date: 'Date',
      expense_type: 'Expense Type',
      expense_type_id: 'Expense Type',
      id: 'Id',
      note: 'Note',
    },

    group_schedules: {
      group: 'Group',
      group_id: 'Group',
      id: 'Id',
      note: 'Note',
      time_from: 'Time From',
      time_to: 'Time To',
      week_day_id: 'Week Day Id',
    },

    groups: {
      activity: 'Activity',
      activity_id: 'Activity',
      capacity: 'Capacity',
      id: 'Id',
      instructor: 'Instructor',
      instructor_id: 'Instructor',
      location: 'Location',
      name: 'Name',
      note: 'Note',
    },

    inflow_items: {
      id: 'Id',
      inflow: 'Inflow',
      inflow_id: 'Inflow',
      product: 'Product',
      product_id: 'Product',
      quantity: 'Quantity',
      sequence: 'Sequence',
    },

    inflows: {
      count_of_items: 'Count Of Items',
      date: 'Date',
      description: 'Description',
      id: 'Id',
      note: 'Note',
    },

    instructors: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    inventories: {
      id: 'Id',
      lead_days: 'Lead Days',
      location: 'Location',
      note: 'Note',
      product: 'Product',
      product_id: 'Product',
      reorder_point: 'Reorder Point',
      stock: 'Stock',
      stock_maximum: 'Stock Maximum',
      stock_minimum: 'Stock Minimum',
    },

    measurements: {
      chest: 'Chest',
      customer: 'Customer',
      customer_id: 'Customer',
      date: 'Date',
      dress_size: 'Dress Size',
      fat: '% Fat',
      height: 'Height',
      hips: 'Hips',
      id: 'Id',
      left_arm: 'Left Arm',
      left_calf: 'Left Calf',
      left_thigh: 'Left Thigh',
      note: 'Note',
      right_arm: 'Right Arm',
      right_calf: 'Right Calf',
      right_thigh: 'Right Thigh',
      waist: 'Waist',
      weight: 'Weight',
    },

    memberships: {
      auto_renewal: 'Auto Renewal',
      customer: 'Customer',
      customer_id: 'Customer',
      fee: 'Fee',
      id: 'Id',
      inscription_fee: 'Inscription Fee',
      note: 'Note',
      plan: 'Plan',
      plan_id: 'Plan',
      total: 'Total',
      validity_from: 'Validity From',
      validity_to: 'Validity To',
    },

    outflow_items: {
      id: 'Id',
      outflow: 'Outflow',
      outflow_id: 'Outflow',
      product: 'Product',
      product_id: 'Product',
      quantity: 'Quantity',
      sequence: 'Sequence',
    },

    outflows: {
      count_of_items: 'Count Of Items',
      date: 'Date',
      description: 'Description',
      id: 'Id',
      note: 'Note',
    },

    payment_methods: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    payment_types: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
      quantity: 'Quantity',
      unit_of_time: 'Unit Of Time',
    },

    payments: {
      amount: 'Amount',
      amount_tendered: 'Amount Tendered',
      change: 'Change',
      concept: 'Concept',
      date: 'Date',
      due: 'Due',
      due_id: 'Due',
      id: 'Id',
      note: 'Note',
      payment_method: 'Payment Method',
      payment_method_id: 'Payment Method',
    },

    plans: {
      activity: 'Activity',
      activity_id: 'Activity',
      fee: 'Fee',
      id: 'Id',
      inscription_fee: 'Inscription Fee',
      name: 'Name',
      note: 'Note',
      number_of_clases: 'Number Of Clases',
      payment_type: 'Payment Type',
      payment_type_id: 'Payment Type',
      tax_rate: 'Tax Rate',
      tax_rate_id: 'Tax Rate',
      validity: 'Validity',
      validity_id: 'Validity',
    },

    products: {
      barcode: 'Barcode',
      department: 'Department',
      department_id: 'Department',
      description: 'Description',
      discontinued: 'Discontinued',
      id: 'Id',
      image_file: 'Image File',
      keywords: 'Keywords',
      name: 'Name',
      note: 'Note',
      precio: 'Precio',
      supplier: 'Supplier',
      tax_rate: 'Tax Rate',
      tax_rate_id: 'Tax Rate',
      unit: 'Unit',
    },

    sale_items: {
      amount: 'Amount',
      id: 'Id',
      price: 'Price',
      product: 'Product',
      product_id: 'Product',
      quantity: 'Quantity',
      sale: 'Sale',
      sale_id: 'Sale',
      sequence: 'Sequence',
    },

    sales: {
      amount: 'Amount',
      amount_tendered: 'Amount Tendered',
      change: 'Change',
      count_of_items: 'Count Of Items',
      customer: 'Customer',
      customer_id: 'Customer',
      date: 'Date',
      description: 'Description',
      id: 'Id',
      note: 'Note',
      payment_method: 'Payment Method',
      payment_method_id: 'Payment Method',
    },

    sales_persons: {
      address: 'Address',
      commission_rate: 'Commission Rate',
      id: 'Id',
      initials: 'Initials',
      mail: 'E Mail',
      name: 'Name',
      note: 'Note',
      other_phones: 'Other Phones',
      phone: 'Phone',
      sales_person_type: 'Sales Person Type',
      tax_code: 'Tax Code',
    },

    task_status: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    task_types: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
    },

    tasks: {
      completion_date_time: 'Completion Date Time',
      contact: 'Contact',
      customer: 'Customer',
      customer_id: 'Customer',
      date_time: 'Date Time',
      description: 'Description',
      id: 'Id',
      mail: 'E Mail',
      note: 'Note',
      phone: 'Phone',
      sales_person: 'Sales Person',
      sales_person_id: 'Sales Person',
      task_status: 'Task Status',
      task_status_id: 'Task Status',
      task_type: 'Task Type',
      task_type_id: 'Task Type',
    },

    tax_rates: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
      rate: 'Rate',
    },

    users: {
      cannot_change_pwd: 'Cannot Change Pwd',
      change_pwd_nxt_logon: 'Change Pwd Nxt Logon',
      disabled: 'Disabled',
      email: 'Email',
      email_verified_at: 'Email Verified At',
      failed_attempts: 'Failed Attempts',
      id: 'Id',
      initials: 'Initials',
      last_login_date: 'Last Login Date',
      last_pwd_change_date: 'Last Pwd Change Date',
      name: 'Name',
      note: 'Note',
      password: 'Password',
      phone: 'Phone',
      pwd_never_expires: 'Pwd Never Expires',
      remember: 'Remember me',
      remember_token: 'Remember Token',
      role: 'Role',
      sales_person: 'Sales Person',
      sales_person_id: 'Sales Person',
    },

    validities: {
      id: 'Id',
      name: 'Name',
      note: 'Note',
      quantiry: 'Quantiry',
      unit_of_time: 'Unit Of Time',
    },

  },
  // Menu group titles.
  Menu: {
    AccessControl: 'Access Control',
    Activities: 'Activities',
    Charts: 'Charts',
    CRM: 'CRM',
    Finances: 'Finances',
    Memberships: 'Memberships',
    Personnel: 'Personnel',
    POS: 'Point of Sale',
    Reports: 'Reports',
    Settings: 'Settings',
    Users: 'User Accounts',
  },

  // Route titles.
  Routes: {
    sampleTable_sampleAction: 'Sample Title for "sampleTable.sampleAction" route',
    _sampleAction: 'Sample Title for "sampleAction" route',
    _browse: '{tablePlural}',
    _clone: 'Clone {tableSingular}',
    _create: 'Create {tableSingular}',
    _edit: 'Edit {tableSingular}',
    _delete: 'Delete {tableSingular}',
    _emailNotify: 'Email Notification',
    _emailVerify: 'Email Verification',
    _forgotPassword: 'Forgot your Password?',
    _login: 'Log in',
    _logout: 'Log out',
    _register: 'Create Account',
    _resetPassword: 'Reset Password',
    _show: 'Show {tableSingular}',
    dummy_chart: 'Dummy Chart',
    dummy_report: 'Dummy Report',
    _report_alpha: '{tablePlural} Report Alphabetical',
    _report_chrono: '{tablePlural} Report Chronological',
  },

  // Selects.
  Selects: {
    blood_type: 'A+,A+,A-,A-,O+,O+,O-,O-,B+,B+,B-,B-,AB+,AB+,AB-,AB-',
    role: 'che,CHECADOR ASISTENCIAS,caj,CAJERO,eje,EJECUTIVO DE VENTAS,asi,ASISTENTE,sup,SUPERVISOR,adm,ADMINISTRADOR',
    sex: 'F,Femenino,M,Masculino',
    shift: 'M,Matutino,V,Vespertino,N,Nocturno,X,Mixto',
    unit_of_time: 'M,MESES,D,DÍAS,Y,AÑOS,W,SEMANAS,I,INFINITO',
    week_day_id: '1,DOMINGO,2,LUNES,3,MARTES,4,MIÉRCOLES,5,JUEVES,6,VIERNES,7,SÁBADO',
  },

  // Table labels singular | plural: 
  Tables: {
    activities: 'Activity | Activities',
    appointment_status: 'Appointment Status | Appointment Status',
    appointment_types: 'Appointment Type | Appointment Types',
    appointments: 'Appointment | Appointments',
    attendances: 'Attendance | Attendances',
    businesses: 'Business | Businesses',
    clock_ins: 'Clock In | Clock Ins',
    currencies: 'Currency | Currencies',
    customer_types: 'Customer Type | Customer Types',
    customers: 'Customer | Customers',
    departments: 'Department | Departments',
    dues: 'Due | Dues',
    employees: 'Employee | Employees',
    expense_types: 'Expense Type | Expense Types',
    expenses: 'Expense | Expenses',
    group_schedules: 'Group Schedule | Group Schedules',
    groups: 'Group | Groups',
    inflow_items: 'Inflow Item | Inflow Items',
    inflows: 'Inflow | Inflows',
    instructors: 'Instructor | Instructors',
    inventories: 'Inventory | Inventories',
    measurements: 'Measurement | Measurements',
    memberships: 'Membership | Memberships',
    outflow_items: 'Outflow Item | Outflow Items',
    outflows: 'Outflow | Outflows',
    payment_methods: 'Payment Method | Payment Methods',
    payment_types: 'Payment Type | Payment Types',
    payments: 'Payment | Payments',
    plans: 'Plan | Plans',
    products: 'Product | Products',
    sale_items: 'Sale Item | Sale Items',
    sales: 'Sale | Sales',
    sales_persons: 'Sales Person | Sales Persons',
    task_status: 'Task Status | Task Status',
    task_types: 'Task Type | Task Types',
    tasks: 'Task | Tasks',
    tax_rates: 'Tax Rate | Tax Rates',
    users: 'User | Users',
    validities: 'Validity | Validities',
  },

  // Vuetify english translations
  $vuetify
}
