// Global helper functions. For details about using plugins see: 
// https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components

/* eslint-disable no-unused-vars */
export const settings = {
  actionColors: {
    clone: "#333333",
    create: "#333333",
    delete: "#333333",
    edit: "#333333",
    show: "#333333",
  },
  
  appColor: "#333333",
  "items-per-page-options": [5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50],
  "show-current-page": true,
  "show-first-last-page": false,
  snackbarTimeout: 2500,
  version: "v0.3 2021-08-16",
}