// Global api functions. For details about using plugins see: 
// https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components

import axios from 'axios';

axios.defaults.withCredentials = true

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
});

export default {
  browse(path, params) {
    return client.get(`${path}`, { params }) // params = query string.
  },

  report(path, params) {
    return client.get(`${path}`, { params }) // params = query string.
  },

  combo(path, name) {
    return client.get(`${path}/${name}/combo`)
  },

  create(path) {
    return client.get(`${path}/create`)
  },

  destroy(path, id) {
    return client.delete(`${path}/${id}`)
  },

  list(path) {
    return client.get(`${path}/list`)
  },

  show(path, id, action) {
    const params = { action }

    return client.get(`${path}/${id}`, { params }) // // params = query string.
  },

  store(path, data) {
    return client.post(path, data)
  },

  update(path, id, data) {
    return client.put(`${path}/${id}`, data)
  },
};