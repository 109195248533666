import { settings } from '@/plugins/settings'

export default {
  namespaced: true,

  state: {
    snackbar: { active: false, color: "", message: "", timeout: "-1" },
  },

  getters: {
    snackbar(state) {
      return state.snackbar
    },
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
  },

  actions: {
    alertSuccess({ commit }, message) {
      commit("SET_SNACKBAR", {
        active: true,
        color: settings.appColor,
        message: message,
        timeout: settings.snackbarTimeout,
      });
    },

    alertError({ commit }, message) {
      commit("SET_SNACKBAR", {
        active: true,
        color: "error",
        message: message,
        timeout: "-1",
      });
    },

    alertWarning({ commit }, message) {
      commit("SET_SNACKBAR", {
        active: true,
        color: "warning",
        message: message,
        timeout: settings.snackbarTimeout,
      });
    },
  }
}