import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { i18n } from '@/plugins/i18n';
import api from '@/plugins/api'
import helper from '@/plugins/helper'
import { settings } from '@/plugins/settings'
import '@/main.scss';
import store from './store'
import axios from 'axios'

const apiPlugin = {
  install() {
    Vue.api = api
    Vue.prototype.$api = api
  }
}

Vue.use(apiPlugin)

const helperPlugin = {
  install() {
    Vue.helper = helper
    Vue.prototype.$helper = helper
  }
}

Vue.use(helperPlugin)

const settingsPlugin = {
  install() {
    Vue.settings = settings
    Vue.prototype.$settings = settings
  }
}

Vue.use(settingsPlugin)

Vue.config.productionTip = false

axios.defaults.withCredentials = true

// For Laravel development server use: http://localhost:8000
// For Actual project use: http://localhost/MemberFit-Api/public
// Note: When testing avoid cross-site URL's because cookies are rejected by
// browser in unsecure (http) connections.
axios.defaults.baseURL = process.env.VUE_APP_API_URL

store.dispatch('auth/me').then(() => { // Retrieve user and then instantiate vue.
  new Vue({
    router,
    vuetify,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
})