import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import Forbidden from '../views/403Forbidden.vue'
import NotFound from '../views/404NotFound.vue'

Vue.use(VueRouter)

/*
Navigation guards implemented thru 'guard' meta field:
  guest: For users not authenticated.
  authenticated: For authenticated users, email verified or not.
  unverified: For authenticated users with email not yet verified.
  verified: Authenticated users with email verified.
*/

var routes = [
  {
    path: '/',
    name: 'home',
    redirect: "/login",
    component: Home,
    meta: { guard: 'anyone' }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { guard: 'anyone' }
  },
  {
    path: '/notFound',
    name: 'notFound',
    component: NotFound,
    meta: { guard: 'anyone' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Add auth routes dinamically. 
import(`./auth.js`).then(({ routes }) => {
  routes.forEach((route) => {
    router.addRoute(route)
  })
});

// Add app routes dinamically.
import { appFilesArray } from './appFilesArray.js'

appFilesArray.forEach((file) => {
  import(`./app/${file}.js`).then(({ routes }) => {
    routes.forEach((route) => {
      router.addRoute(route)
    })
  });
})

// Navigation guards (middleware) implemented:
//   guest: not authenticated.
//   guard: authenticated.
//   unverified: authenticated and email not verified.
//   verified: authenticated and email verified.

function allowedRoute(route) {
  return store.getters["auth/allowedRoute"](route)
}

function loginRoute(to) {
  // Ignore intented routes with query parameters. Ignore logout intended route as well.
  if (Object.keys(to.query).length !== 0 || to.name == 'logout') return { name: 'login' }

  return { name: 'login', query: { intended: to.name } }
}

router.beforeEach((to, from, next) => {
  if (to.meta.guard == 'verified') { // App's routes ...
    if (store.state.auth.verified) {
      if (allowedRoute(to.name)) {
        next()
      } else {
        next({ name: 'forbidden' })
      }
    } else {
      if (store.state.auth.authenticated) {
        next({ name: 'emailNotify' })
      } else {
        next(loginRoute(to))
      }
    }

  } else if (to.meta.guard == 'guest') { //register, login, forgotPassword, passwordReset ...
    if (store.state.auth.verified) {
      next({ name: process.env.VUE_APP_LOGIN_NEXT_ROUTE })
    } else {
      if (store.state.auth.authenticated) {
        next({ name: 'emailNotify' })
      } else {
        next()
      }
    }

  } else if (to.meta.guard == 'authenticated') { //account, logout ...
    if (store.state.auth.authenticated) {
      next()
    } else {
      next(loginRoute(to))
    }

  } else if (to.meta.guard == 'unverified') { // emailNotify, emailVerify ...
    if (store.state.auth.verified) {
      next({ name: 'home' })
    } else {
      if (store.state.auth.authenticated) {
        next()
      } else {
        next(loginRoute(to))
      }
    }

  } else if (to.meta.guard == 'anyone') { // home, forbidden, notFound
    next()

  } else { // Catch guard mistyped or not yet implemented.
    next({ name: 'notFound' })
  }
})

export default router
